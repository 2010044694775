@import "variables.scss";

#spacehub #mainLayout #mainContent #tradeChattingRoom-230528{
    max-width: 768px;
    margin-bottom: 56px;

    .trade-info-container {
        flex-direction: row;

        .store-info-container {
            border : $defaultBorder;
            border-radius: 8px;
            padding: 12px;                       

            .store-name-panel {
                flex-direction: row;
                margin-bottom: 16px;
            }
        }

        .action-panel {
            margin: 16px 0px 4px;
            flex-grow: 1;
            flex-direction: row;

            .btn-action {
                height: 100%;
            }
            .btn-cancel {
                flex-grow: 1;
            }
            .btn-complete {
                margin-left: 8px;
                flex-grow: 6;                
            }
        }


        .trade-item-container {
            flex-direction: row;
            border : $defaultBorder;
            padding: 12px;
            border-radius: 8px;
            margin-left: 16px;
            flex-grow: 1; 
            
    
            .item-info-panel {                
                flex-direction: row;
                margin-bottom: 12px;
        
                .MuiChip-root {
                    margin-left: 4px;
                }
                .MuiChip-root:first-child {
                    margin-left: 0px;
                }                
            }
                
            .price-info-panel {
                flex-direction: row;
                align-items: flex-end;
            }
        
            .action-panel {
                margin-top: 8px;
            }
    
            .item-option-container {
                margin-left: 24px;
    
                .item-option-list {
                    margin-top : 8px;
                    .option-container {
                        flex-direction: row;
            
                        .value-label {
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
    
    
    .message-list-container {
        margin-top: 16px;

        .message-container {
            flex-direction: row;
            margin-top: 16px;

            .name-label {
                margin-left: 12px;
            }

            .message-content-container {
                margin-left: 8px;
                

            }
        }

        .speech-bubble {
            position: relative;
            border-radius: .4em;
            padding: 12px;
            margin-top: 4px;
            color : black;
        }
        
        .speech-bubble::after {
            content: '';
            position: absolute;
            top: 20px;
            width: 0;
            height: 0;
            border: 12px solid transparent;
        }

        .message-container.message-left {
            .speech-bubble {
                margin-left: 12px;
                background: white;
            }

            .speech-bubble::after {
                left: 0;
                border-right-color: white;
                border-left: 0;
                border-top: 0;
                margin-top: -10px;
                margin-left: -12px;
            }
        }

        .message-container.message-right {
            justify-content: flex-end;
            margin-right: 12px;

            .speech-bubble {
                margin-left: 0px;
                background: $primaryColor;
            }
            
            .speech-bubble::after {
                right: 0;
                border-left-color: $primaryColor;
                border-right: 0;
                border-top: 0;
                margin-top: -10px;
                margin-right: -12px;
            }
        }

        
        
        
        .time-label {
            display: flex;
            align-items: flex-end;
            margin-left: 8px;

        }
    }
    
    
    .message-input-panel {
        max-width: 768px;        
        width: -webkit-fill-available;
        width: -moz-available;
        position : fixed;
        bottom: 0px;
        padding-bottom: 12px;
        z-index: 999;
        background-color: black;
        flex-direction: row;

        .message-input {
            flex-grow: 1;
            fieldset {
                border-color: #aaa;
            }
        }
    }
}