@import "variables.scss";

#spacehub #spacehubMainAppBar{
    
    position: sticky;
    z-index: 9;
    width: 100%;
    top : 0px;


    .path-tracker-container {
        flex-direction: row;

        .path-label {
            cursor: pointer;
        }
        .path-label:last-child {
            cursor: default;
        }

        .path-symbol {
            margin : 0px 4px;
        }   
    }



    .user-profile{
        width: 32px;
        height: 32px;        
        border-radius: 50%;        
        cursor: pointer;
    }

}