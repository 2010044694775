@import "variables.scss";

#spacehub #mainLayout #mainContent #postWritePage{
    max-width: 768px;
    .description-label {
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .title-text {
        margin-top: 8px;
    }
    .btn-write {
        margin-top : 48px;
    }
}