@import "variables.scss";

#spacehub #mainLayout #mainContent #guestOrderInqueryPage-230624{
    align-items: center;
    
    .title-label {
        margin-top: 120px;
    }
    .inquery-panel {
        margin-top: 24px;
        width: 400px;
        border : $defaultBorder;
        border-radius: 4px;
        padding: 24px;

        .btn-submit {
            margin-top: 16px;
        }
    }


}