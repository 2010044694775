@import "variables.scss";

#spacehub #loginPage-230531{
    height: 100vh;
    align-items: center;
    padding : 24px;

    .site-title {
        margin-top: 120px;
        cursor: pointer;
    }

    .login-panel {
        margin : 48px 0px 16px;
        max-width: 480px;
        width: 100%;
        border : $defaultBorder;
        border-radius: 4px;
        padding : 32px;

        .input-password {
            margin: 24px 0px 48px;
        }        
    }

    .signup-panel {
        max-width: 480px;
        width: 100%;

        .btn-signup {
            cursor: pointer;
        }
    }
}



/** Mobile size **************************************************************/
@media screen and (max-width: $mobileScreenSize) {
    #spacehub #loginPage-230531{        
        .site-title {            
            font-size: 2rem;
        }
    
        .login-panel {
            margin : 32px 0px 16px;
            padding : 24px;
    
            .input-password {
                margin: 12px 0px 24px;
            }
        }            
    }    
}