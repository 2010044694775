@import "variables.scss";

#spacehub #mainLayout #mainContent #createAndEditStorePage-230516{
    max-width: 768px;

    .store-input-panel {
        flex-direction: row;
        align-items: center;        
        .store-name {
            flex-grow: 1;
        }

        .btn-check {
            margin-left: 12px;
        }
    }

    .introduce {
        margin-top: 24px;
    }

    .btn-create {
        margin-top: 24px;
    }
    
}