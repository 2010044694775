@import "variables.scss";

#spacehub #mainLayout #mainContent #storePage-230516{
    .item-list {
        .trade-container {
            position: relative;
            border : 1px solid #777;
            border-radius: 4px;
            padding: 16px;
            height: 100%;

            .btn-more {
                position: absolute;
                right: 8px;
                top : 8px;
            }
            
            .action-panel {
                margin-top: 8px;
            }
        }
    }
}