@import "variables.scss";

#spacehub #mainLayout #mainContent #groupSpaceThreadPage-230619{
    // Begin space-info-container
    .space-info-container {
        position: relative;
        height: 64px;        
        overflow: hidden;
        margin-bottom: 24px;
                
        .banner {
            position: absolute;
            width: 100%;
            left : 0;
            top : 0;
            z-index: -2;
        }
        .banner-overlay {
            position: absolute;
            left : 0;
            top : 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background-color: rgba(0, 0,0, 0.3);
        }

        .space-name-panel {
            position: absolute;
            flex-direction: row;
            left : 0px;
            top :0px;
            padding : 16px;
            background-color: rgba(0, 0,0, 0.4);
            border-radius: 8px;
            
            .space-name {
                align-items: center;
                display: flex;
            }
        }
    } 
    // End space-info-container 

    .menu-panel {
        display: flex;
        align-items: center;
        background-color: #272727;
        margin-bottom: 24px;
        border-radius: 4px;
        button {
            height: 64px;
        }
    }

}