@import "variables.scss";

#spacehub #mainLayout #mainContent #instanceSpaceHomePage-230624{

    .instance-image-panel {
        margin-top: 32px;
        flex-direction: row;        
        align-items: flex-end;

        .img-influencer {
            width: 380px;
        }

        .img-company {
            width: 380px;
            height: 254px;
            background: white;
            object-fit: contain;
        }
    }

    .product-list {
        margin-top: 8px;
        flex-direction: row;

        .product-container {
            width: 148px;
            margin-right: 16px;
            .product-img {
                width: 100%;
            }
            .add-cart-panel {
                margin-top: 4px;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }

        }

    }


    
}