@import "variables.scss";

#spacehub #mainLayout #mainContent #trade-item-container-230528{        
    height: 100%;

    .item-info-panel {                
        flex-direction: row;
        margin-bottom: 12px;



        .MuiChip-root {
            margin-left: 4px;
        }
        .MuiChip-root:first-child {
            margin-left: 0px;
        }                
    }

    .item-option-list {
        margin-top : 8px;
        .option-container {
            flex-direction: row;

            .value-label {
                margin-left: 8px;
            }
        }
    }

    .price-info-panel {
        flex-direction: row;
        align-items: end;
    }

    .action-panel {
        margin-top: 8px;
    }
}