@import "variables.scss";

#spacehub #mainLayout #mainContent #registerItem-230518{
    max-width: 800px;

    .item-option-panel{
        margin-top: 28px;
        .option-row {
            margin-top: 12px;
            flex-direction: row;

            .input-option-value {
                margin-left: 16px;
                max-width: 100px;
                input {
                    text-align: center;
                }                
            }
        }
    }
    
    .required-level-panel {
        flex-direction: row;
        margin-top: 28px;

        .input-req-level {
            margin-top: 8px;
            max-width: 80px;
            
            input {
                text-align: center;
            }
        }
    }
    
    .checkbox-panel {
        margin-top: 16px;
        flex-direction: row;
    }
    .price-panel {
        flex-direction: row;
        align-items: end;
        input {
            text-align: center;
        }
    }

    .btn-register {
        margin-top: 24px;
    }
}