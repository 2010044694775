$primaryColor: #508ce6;
$secondaryColor: #3cc8aa;
$borderColor: white;
$disableTextColor: #484848;
$greyTextColor : #787878;
$maxWidth : 720px;
$caretColor : white;

$backgroundColor : white;
$lightBackgroundColor: #444;

$textColor : white;
$textSecondaryColor : #777;


.spacehub-text-editor {
	display: flex;	
	position: relative;
	flex-direction: column;
	width: 100%;
	height : 100%;
	font-family: "Maison Neue";
	line-height: 1.3;
	font-size : 16px;
	border : 1px solid $borderColor;
	padding: 0px 0px 12px;
	

	.spacehub-text-navbar {
		border-bottom : 1px solid $borderColor;
		position : sticky;
		height : 47px;
		top : 0px;
		left : 0px;
		z-index:  10;
		width: 100%;

		.primary-button	 {
			color : #508ce6;
			font-weight: bold;
			position : absolute;
			font-size: 14px;
			right : 0px;
			top : 0px;
			width : 48px;
			height : 48px;
		}
	}

	.spacehub-text-body-container {
		height : 100%;
		min-height: 135px;

		.spacehub-text-body {
			padding : 0px 20px;
			overflow : auto;
			display: flex;
			flex-direction: column;

			.spacehub-textarea {
				width : 100%;
				resize : none;
				background-color:  transparent;
				caret-color: $caretColor;
				border : none;
				outline : none;
				font-size : 16px;
				font-family: "Maison Neue";
				padding : 0px;
				margin : 0px;
				line-height: 1.31;
				color : $textColor;
			}
			.spacehub-textarea::placeholder {
				color: #999;
			}
			.text-area-container {
				display : flex;
				justify-items: center;
				position : relative;
			}



			.image-container {
				width : 100%;
				position : relative;
				margin: 8px 0px;
				outline:none;

				.image-wrapper {
					position: relative;
					.image {
						max-width: 480px;
						width : 100%;
						object-fit: cover;
						border-radius: 16px;
					}
				}				
			}


			.youtube-container {
				margin-top: 8px;
				margin-bottom: 8px;
				border-radius: 8px;
				align-items: center;
				padding: 8px;
				outline : none;
				position : relative;
				width: fit-content;
			}


			.link-preview-container {
				margin-top: 8px;
				margin-bottom: 8px;
				width : fit-content;
				background-color : $lightBackgroundColor;
				border-radius: 8px;
				display:  flex;
				align-items: center;
				padding: 8px;
				outline : none;
				position : relative;

				.image {
					width : 65px;
					height: 65px;
					border-radius: 8px;
					outline:  none;
					object-fit: cover;
				}

				.title-container {
					margin-left: 8px;
					font-size : 12px;
					text-align: left;
				}
				.title-desc-container {
					margin-left: 8px;
					text-align: left;
					flex-grow: 1;
					.title {
						font-family: "Maison Neue";
						font-size: 16px;
						color: $textColor;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						max-width: calc(100vw - 128px);
						-webkit-box-orient: vertical;
					    -webkit-line-clamp: 2;
						word-break: break-word;
						display: -webkit-box;
						white-space: pre-wrap;
						line-height: 1.2;
						font-weight: bold;
					}
					.url  {
						font-family: "Maison Neue";
						font-size: 16px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						max-width: calc(100vw - 128px);
						margin-top: 2px;
						color : #777;
						font-weight: 500;
					}
				}
			}


			.empty-area {
				width: 100%;
			    flex-grow: 1;
			}

			.delete-icon {
				position : absolute;
				width: 40px;
				height: 40px;
				padding : 0px;
				top : -20px;
				right : -20px;

				svg {
					width: 100%;
					height: 100%;
				}
			}
			
		}
	}


	.toolbar-panel {
		position : sticky;
		height : 40px;
		top : -24px;
		left : 0px;
		z-index:  10;
		width: 100%;		
		display: flex;
		padding : 4px;
		align-items: center;
		margin-bottom: 12px;
		background-color: white;
		border-bottom: 2px solid #AAA;
		
		.toolbar-icon {
			width : 40px;
			height : 40px;
			padding : 0px;
			color : black;
		}

		hr {
			background-color: #AAA;
		}
	}
}


.mb-8 {
	margin-bottom: 8px !important;;
}

.mb-16 {
	margin-bottom: 16px !important;;
}

.mt-16 {
	margin-top: 16px !important;;
}

.flex-row-fill {
	flex-grow: 1;
}

.loading-progress-container {
	width : 100%;
	height : calc(100vh - 96px - 32px);
	display: flex;
    align-items: center;
	justify-content: center;



	.loading-progress.MuiCircularProgress-indeterminate  {
		color : #3cc8aa;
	}
}


body.modal-open {
    overflow: hidden;
}

.suggestion-layout{		
	z-index: 999;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	top : 0px;	

	.suggestion-container {
		margin-left : 16px;
		margin-right : 16px;		
		height : 216px;
		min-width: 360px;
		background: $lightBackgroundColor;		
		overflow: auto;
		position: fixed;

		// user list item
		.user-container {
			display : flex;
			align-items:  center;
			margin : 0px 0px 8px;
			padding : 12px;			
			cursor : pointer;

			.user-image {
				width : 32px;
				height : 32px;
				min-height: 32px;
				border-radius: 50%;
			}

			.username-container {
				margin-left: 8px;
				text-align: left;
				width : 100%;
				max-width: calc(100% - 24px);
				
				.display-name {
					color : $textColor;
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: break-all;
					white-space: nowrap;
					font-size : 14px;
				}

			}
		}

		.selected-user {
			background-color: $secondaryColor;
		}

	}
}


















