@import "variables.scss";

#spacehub #mainLayout #mainContent #tradeHome-230526{
    .item-list {
        .trade-container {
            position: relative;
            border : 1px solid #777;
            border-radius: 4px;
            padding: 16px;
            height: 100%;

            .store-info-panel {
                flex-direction: row;                
                .store-name {
                    cursor: pointer;
                }
                margin-bottom: 8px;
            }

            .action-panel {
                margin-top: 8px;
            }
        }
    }
}