@import "variables.scss";

#spacehub #mainLayout #mainContent .space-post-and-detail-230512{
    position: relative;    
    
    .post-list-wapper {        
        overflow-y: scroll;
        height: calc(100vh - $appbarHeight - $mainContentPadding*2 - $threadBannerHeightWithPadding);
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .post-list-wapper::-webkit-scrollbar {
        display: none;
    }
    
    .post-detail-wapper {
        padding-left: 24px;
        overflow-y: scroll;
        height: calc(100vh - $appbarHeight - $mainContentPadding*2 - $threadBannerHeightWithPadding);
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        .commnet-section-divider {
            margin : 24px 0px 8px
        }
        .new-comment {
            margin-top : 24px;
        }
        .btn-close-detail {
            display: none;
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .post-detail-wapper::-webkit-scrollbar {
        display: none;
    }
}



/** Mobile size **************************************************************/
@media screen and (max-width: $mobileScreenSize) {
    #spacehub #mainLayout #mainContent .space-post-and-detail-230512{
        .post-detail-wapper {            
            position: absolute;
            z-index: 1;
            background-color: $defaultBackgroundColor;
            padding: 12px;
            width: -webkit-fill-available;
            width: -moz-available;            

            .btn-close-detail {
                display: initial;
                position: absolute;
                top : 0px;
                right : 0px;
            }
        }
    }    
}