@import "variables.scss";

#spacehub #mainLayout #mainContent .post-detail-component{
    .writer-info-panel {
        flex-direction: row;
        align-items: center;

        .user-profile-image {
            width: 40px;
            border-radius: 50%;
        }
    }

    .post-description {
        margin-top: 16px;
    }
}