@import "variables.scss";

#spacehub #mainLayout #mainContent #groupSpaceHomePage-230619{
    // Begin space-info-container
    .space-info-container {
        position: relative;
        overflow: hidden;
        max-height: 300px;

        .banner {            
            width: 100%;
            z-index: -2;
        }
        .banner-overlay {
            position: absolute;
            left : 0;
            top : 0;
            width: 100%;
            height: 100%;            
            background-color: rgba(0, 0,0, 0.3);
        }

        .space-name-panel {
            position: absolute;
            flex-direction: row;
            left : 16px;
            bottom : 16px;
            padding : 16px;
            background-color: rgba(0, 0,0, 0.8);
            border-radius: 8px;

            .space-thumbnail {
                width: 96px;
                height: 96px;
                border-radius: 8px;
            }
            .space-name {
                margin-left: 24px;
                align-items: center;
                display: flex;
            }
        }
    } 
    // End space-info-container 


    .content-container {
        .contents-column {            
            .thread-container {
                max-height: 100%;
                overflow-y: auto;
            }
            
            .contents-card {
                margin-top: 16px;
                height: 100%;
                
                .section-category {
                    font-size: 14px;
                    margin-bottom: 8px;       
                }                
                .home-section-card-contents {
                    height: 100%;
                }
    
                pre {
                    white-space: pre-wrap;
                    word-wrap: break-word;
                }

                .introduce-card .section-content {
                    height: 100px;
                    overflow-y: AUTO;
                }                
            }
            .home-section.introduce {
                overflow-y: auto;

            }
        }

        .contents-column.introduce-column {
            height: calc(100vh - $appbarHeight - 380px );
            
        }

        .contents-column.list-column {
            height: calc(100vh - $appbarHeight - 380px );            
        }
    }
}