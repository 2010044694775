@import "variables.scss";


/** Start .comment-section ******************************************************/
#spacehub #mainLayout #mainContent{
.comment-section-230510 {
    .comment-header {            
        flex-direction: row;
        height: 44px;
        align-items: center;


        .icon-container-wapper {
            width: 36px;
            height: 36px;
            margin-left: 4px;
            
            .icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 300px;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);                    
            }                
            svg {
                width: 16px;  
                height: 16px;
                
            }
        }
    }
    .sort-selector{
        width: 105px;
        text-align: center;
        fieldset {
            border : none
        }
    }
    // 댓글 입력, 답글 입력
    .comment-input-container, .reply-input-container {        
        border : 1px solid #444;
        padding : 8px;
        border-radius: 8px;
        .comment-input-content {
            flex-direction: row;
            .user-icon {
                width: 28px;
                height: 28px;
            }
            .new-comment-input {
                margin-left: 8px;
                .MuiInputBase-root {
                    padding: 4px;
                }
                fieldset {
                    border: none
                }
            }                    
        }

        .btn-comment-container {
            display: flex;
            justify-content: flex-end;
                                
            .btn-comment {
                border-radius: 18px;
            }            
        }                
    }

    .reply-input-container {
        margin-top: 8px;
        margin-left: 32px;
    }

    
    // 댓글 목록
    .comment-list {                
        padding: 4px 0px 4px 0px;
        margin-top: 16px;          

        .comment-and-reply-container {
            border-bottom: 1px solid #ccc;
            padding-bottom: 8px;

            .comment-card,
            .reply-card {
                position: relative;
                margin-top: 8px;            
                padding: 0px 12px 4px 8px;

                .comment-cotent-container {
                    flex-direction: row;

                    .writer-profile {
                        margin-top: 4px;
                    }

                    .comment-info {
                        margin-left: 12px;

                        .comment-writer-container {
                            flex-direction: row;
                            align-items: center;

                            .writer-name {
                                font-weight: 500;
                                font-size: 14px;
                                margin-right: 4px;
                            }

                            .create-time {
                                margin-left: 4px;
                                font-weight: 400;
                                font-size: 13px;                            
                            }
                        }
                    }

                    .description {
                        font-family: 'Noto Sans KR';
                        font-weight: 400;
                        font-size: 1rem;
                        margin: 4px 0px 0px;
                    }
                }

                // end comment-cotent-container

                .action-container {
                    flex-direction: row;
                    margin-left: 28px;

                    .btn-write-reply {
                        justify-content: flex-start;
                        padding-left: 8px;
                        min-width: 54px;
                    }                
                }

                .more-button {
                    position: absolute;
                    top: -4px;
                    right: -0px;
                }
            }

        }
        
        // end comment card
    } // end comment-list

    .reply-list-panel {
        padding-left: 32px;        
    }

} // end comment-section    
} // end #flabo #villageDefaultLayout #diablo2DefaultLayout
/** End #item-db-page ********************************************************/

#FlaboVilageCommentUpdatePlanel {
    .MuiDrawer-paper {
        border-radius: 12px 12px 0px 0px;
        .comment-update-panel {
            padding: 16px 16px 8px 16px;
    
            .panel-title {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 8px;
            }            
            .action-button-container {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                margin-top: 8px;

                .cancel-button {
                    margin-left: 8px;                    
                }
                
                button:hover{
                    background-color: #EBEBEB;                    
                }
            }

        }        
    }    
}



/** Mobile size **************************************************************/
@media screen and (max-width: $mobileScreenSize) {
    #flabo #villageDefaultLayout #diablo2DefaultLayout {
        .comment-section {
            .comment-header {                
                .icon-container-wapper {                    
                    margin: 0px;
                    .icon-container {
                        width: 18px;
                        height: 18px;                        
                    }
    
                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
            .comment-input-container, .reply-input-container {                
                padding: 4px;
                border-radius: 4px;
    
                .comment-input-content {
                    flex-direction: row;                
                    .user-icon {
                        width: 20px;
                        height: 20px;
                    }
                    .new-comment-input {
                        margin-left: 4px;
                        .MuiInputBase-root {
                            padding: 0px;
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }
            }
    
    
                    .comment-list {
                        padding: 4px 0px 4px 0px;
            
                        .comment-card,
                        .reply-card {
                            margin-top: 4px;
                            padding: 0px 0px 4px 0px;
            
                            .comment-cotent-container {
                                
                                .comment-info {
                                    margin-left: 12px;
            
                                    .comment-writer-container {
                                        flex-direction: row;
                                        align-items: center;                                    
            
                                        .writer-name {                                        
                                            font-size: 11px;
                                            margin-right: 4px;
                                        }
    
                                        .writer-force-chip {
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            font-size: 10px;
                                            font-weight: 400;
                                        }
            
                                        .create-time {
                                            margin-left: 4px;                                        
                                            font-size: 10px;                                        
                                        }
                                    }
                                }
            
                                .description {
                                    font-family: 'Noto Sans KR';                                
                                    font-size: 12px;
                                    margin: 4px 0px 0px;
                                }
                            }
            
                            // end comment-cotent-container
            
                            .action-container {                            
                                margin-left: 28px;
            
                                .btn-write-reply {
                                    justify-content: flex-start;
                                    padding-left: 8px;
                                    min-width: 40px;
                                }
            
                                button {
                                    color: rgba(60, 60, 67, 0.6);
                                }
            
                                button:hover {
                                    background-color: rgba(0, 0, 0, 0);
                                }
                            }
            
                            .more-button {
                                position: absolute;
                                top: 0px;
                                right: 0px;
                                svg {
                                    width: 16px;
                                    height: 16px;
                                }
                            }
                        }
            
                        // end comment card
                    }
            
                    // end comment-list
        }
    }}