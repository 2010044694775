// 899px is md size
$mobileScreenSize : 899px;

$sidebarWidth : 240px;
$appbarHeight : 64px;
$mainContentPadding : 24px;
$threadBannerHeightWithPadding : 88px;

$defaultBackgroundColor : #121212;
$defaultTextColor : white;

$primaryColor : lightcoral;

$defaultBorderColor : #777;
$defaultBorder : 1px solid $defaultBorderColor;