@import "variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.flex-grow {
  flex-grow: 1;
}

pre {
  margin : 0px;
  white-space: pre-wrap;
  word-wrap: break-word;
}



// Alert Dialog
.swal2-shown {
  padding-right: 15px;
}

.btn-app-alert-primary {
  background-color: lightcoral;
  color: white;
  height: 40px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-left: 16px;
  min-width: 90px;
}

.btn-app-alert-default {
  background-color: #777;
  color: $defaultTextColor;
  height: 40px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-left: 16px;
  min-width: 90px;
}



///////////////////////////////////////////////////////////////////////////////////////////////////
///                               Scroll Bar                                                     //
///////////////////////////////////////////////////////////////////////////////////////////////////
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #BBB; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888; 
}



// Assert layer
#SHAssertErrorLayer {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  color : white;
  font-size: 64px;
  text-align: center;
  padding-top: 100px;
}
