@import "variables.scss";

#spacehub #signupPageEmail-230602{
    height: 100vh;
    align-items: center;
    padding : 24px;
    
    .site-title {
        margin-top: 120px;
        cursor: pointer;
    }

    .signup-panel {
        margin : 48px 0px 16px;
        max-width: 480px;
        width: 100%;
        border : $defaultBorder;
        border-radius: 4px;
        padding : 32px;

        .email {
            margin-bottom: 16px;
        }

        .nickname-panel {
            flex-direction: row;
            align-items: flex-end;

            .nickname-input {
                flex-grow: 1;
            }
            .btn-check {
                margin-left: 16px;
            }
        }


        .input-password {
            margin: 16px 0px;
        }

        .btn-signup {
            margin-top : 40px;
        }

    }   
}

/** Mobile size **************************************************************/
@media screen and (max-width: $mobileScreenSize) {
    #spacehub #signupPageEmail-230602{        
        .site-title {            
            font-size: 2rem;
        }
    
        .signup-panel {
            margin : 32px 0px;
            padding : 20px;
    
            .nickname-panel {                
                .btn-check {
                    margin-left: 8px;
                    width: 100px;
                }
            }    
    
            .input-email {
                margin: 12px 0px;
            }
    
            .btn-signup {
                margin-top : 24px;
            }    
        }
    }
}