@import "variables.scss";

#spacehub #mainLayout #mainContent .post-list{        
    .post-list-item {
        margin-bottom: 16px;

        .user-profile-image{
            width: 40px;            
            border-radius: 50%;
            padding: 4px;
        }

        .description-container {
            position: relative;

            .post-title {
                cursor: pointer;
            }
            .post-description {
                cursor: pointer;
                margin-top: 8px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                        line-clamp: 3; 
                -webkit-box-orient: vertical;
            }

            .description-overlay {
                z-index: 100;
                background: linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,1));
                width: 100%;
                height: 56px;
                position: absolute;
                left: 0px;
                bottom : 0px;

            }
        }

        
        .comment-info-container{
            padding : 0px 8px;
            display: flex;
            flex-direction: row;
            .comment-count {
                margin-left: 8px;
            }
        }
    }
}

