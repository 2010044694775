@import "variables.scss";

#spacehub #sidebar{
    max-width: $sidebarWidth;
    flex-shrink: 0;
        
    .MuiDrawer-paper {
        width: $sidebarWidth;
        box-sizing: border-box;
    }

    .logo {
        width: 100%;
        padding: 8px;
        margin-top: 8px;
        cursor: pointer;
    }

    .section-label {
        margin : 0px 8px
    }

    

    .open-space-list {
        padding-top: 0px;
    }

    ul a {
        text-decoration: none;
        color : white;
    }
    ul a:visited {
        color: inherit;
    }

    .terms-panel {
        flex-direction: row;
        justify-content: center;
        margin-top: 48px;

        .terms {
            cursor: pointer;
        }
    }
}

/** Mobile size **************************************************************/
@media screen and (max-width: $mobileScreenSize) {
    
}