@import "variables.scss";

#spacehub #mainLayout{  
  display: flex;
  width: 100%;

  .main-content-container {
    width: calc(100% - $sidebarWidth);
    flex-grow: 1;
    overflow: hidden;
    
    #mainContent {
      padding : $mainContentPadding;
      height: calc(100vh - $appbarHeight);
      overflow-y: scroll;      
    }
  }  
}


/** Mobile size **************************************************************/
@media screen and (max-width: $mobileScreenSize) {
  #spacehub #mainLayout{
    .main-content-container {
      #mainContent {
        padding : 12px;
      }
    }  
  }
}