@import "variables.scss";

#spacehub #mainLayout #mainContent #postEditPage{
    max-width: 768px;
    .description {
        margin-top: 24px;
    }
    .btn-write {
        margin-top : 24px;
    }
}